import Vue from 'vue'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import L from 'leaflet'

import {
  getMapStyle,
  getStationPopupContent,
  getRainpathSource,
  getWindArrow,
  drawArrowHead
} from './helper'
import { WeatherStationData } from '@/store/weatherstation/definitions'
import { WeatherEventStation } from '@/store/event/definitions'
import preferences from '@/services/preferences'
import { WeatherTime } from '@/store/time/definitions'

import colors from '@/styles/colors'

mapboxgl.accessToken = 'pk.eyJ1IjoibWRhcnRpYyIsImEiOiJjaXpqenNkcHcwMDVlMnF0OWFocTJ6ZWYxIn0.HaUGbDl8wwePzrZ-4pQBmA'

// const COLOR_GOLD = 'var(--color-gold)'
const COLOR_GOLD = '#b9a26c'
const COLOR_OVER_LIMIT = colors['color-red']
const TARGET_COLOR = '#777777'
const MAP_TARGET_CIRCLE_INTERVAL = 2500
const MAP_TARGET_CIRCLE_ITERATION = 42

const SOURCE_RADAR_FULL = 'source-radar-full'

export default Vue.extend({
  props: {
    id: {
      type: String,
      default: 'id1'
    },
    event: {
      type: Object,
      required: true
    },
    bbox: Object,
    times: {},
    timesIndex: Number,
    numberOfForwardLayers: Number,
    /** @type {ImageStrategy} */
    imageResolution: {
      type: String,
      default: '/half_image.png'
    },
    isLayerOsmDisplayed: {
      type: Boolean,
      default: true
    },
    isLayerRainpathDisplayed: Boolean,
    isLayerWeatherStationsDisplayed: Boolean,
    areStationsDisplayed: Boolean,
    /** @type {ForecastDataRain} */
    rain: Object,
    /** @type {WeatherStationData[]} */
    stationsData: {
      default: () => ([])
    },
    currentTime: {
      type: Object,
      default: () => ({})
    },
    // this prop help us to center the map
    // each time we have to
    // nowcasting component will bind centerMap to view
    // so when user change to zoom, radar, forecast
    // the map will 'fitCenter'
    centerMap: {
      type: String,
      default: null
    },
    osmOpacity: {
      type: Number,
      required: true
    },
    radarOpacity: {
      type: Number,
      required: true
    },
    displayWarningMessage: {
      type: Boolean,
      default: false
    },
    displayInfoMessage: {
      type: Boolean,
      default: false
    },
    warningMessage: {
      type: String
    },
    radarPeriods: {
      type: Array,
      default: () => ([])
    },
    isLayerWindArrowsDisplayed: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      colors: [
        { wind: '#DD66D8', gust: '#F1C4E6' },
        { wind: '#55C116', gust: '#B8F3A3' },
        { wind: '#00B3FF', gust: '#77e7fd' }
      ]
    }
  },
  computed: {
    isIOS (): boolean {
      return L.Browser.mobile === true && L.Browser.safari === true
    },
    isMobile (): boolean {
      return L.Browser.mobile === true
    },
    center () {
      return [
        this.event.location.coordinates[1],
        this.event.location.coordinates[0]
      ]
    },
    mapId () {
      return 'map' + this.id
    },
    stationsOverLimit (): [WeatherEventStation] {
      if (!this.stationsData) return null
      // return this.event.stations
      const overLimit = this.event.stations.filter((s: WeatherEventStation) => {
        const stationDetail: WeatherStationData = this.stationsData.find(
          (item: WeatherStationData) => item ? item.stationId === parseInt(s.properties.reference) : false
        )
        return stationDetail ? stationDetail.isRainIntensityOverLimit : false
      })
      return overLimit.length > 0 ? overLimit : null
    },
    arrowVisibility () {
      return this.isLayerWindArrowsDisplayed ? 'visible' : 'none'
    }
  },

  async mounted () {
    // we set this var to know we are with a live map
    // we set to false when we'll destroy the mapbox map
    // and so, we won't save the close popup event
    this.savePrefs = true
    const styleConfig = {
      // ...config,
      COLOR_TARGET: TARGET_COLOR,
      COLOR_EVENT: COLOR_GOLD,
      COLOR_OVER_LIMIT: COLOR_OVER_LIMIT,
      RAIN_PATH_COLOR: '#D63333',
      RAIN_PATH_LINE_MIN_WIDTH: 2,
      RAIN_PATH_LINE_MAX_WIDTH: 6,
      MAP_TARGET_CIRCLE_INTERVAL,
      MAP_TARGET_CIRCLE_ITERATION
    }
    const mapZoomPrefKey = 'map_' + this.id
    const style: any = getMapStyle(
      this.event,
      this.event.location.coordinates,
      this.stationsOverLimit,
      styleConfig
    )
    this.map = new mapboxgl.Map({
      container: this.mapId,
      style,
      maxZoom: 15,
      minZoom: 6,
      center: this.event.location.coordinates,
      zoom: preferences.zooms[mapZoomPrefKey] || 10
    })
    // Array of weather station popups
    // Useful to show / hide / remove popups
    this.stationPopups = []
    // Array of weather station wind popups
    // Useful to show / hide / remove popups
    this.windPopups = []

    this.map.dragRotate.disable()
    this.map.touchZoomRotate.disableRotation()

    // Disable drag for iOS devices
    this.isMobile && this.map.dragPan.disable()

    // get available times for radar image
    this.map.on('load', () => {
      if (this.rain) {
        this.drawRainpath(this.rain.speed, this.rain.direction)
      }

      // create the source
      // When map is ready, load radar images
      // this.showRadarImage()

      // Init station popup: reopen it if user preference are setting
      // this.createOrReplaceStationPopup()

      if (this.isLayerRainpathDisplayed === false) {
        this.hideLayer('layer-rainpath')
        this.hideLayer('layer-rainpath-head')
      }
      if (this.isLayerWeatherStationsDisplayed === false) {
        this.hideLayer('layer-stations-circle')
        this.hideLayer('layer-stations-circle-over-limit')
        this.hideLayer('layer-stations-symbol')
      } else {
        this.createOrReplaceStationPopup()
      }
      if (this.currentTime) {
        this.addSourceAndLayer(this.currentTime.utc)
      }

      this.map.setPaintProperty('layer-raster-osm', 'raster-opacity', this.osmOpacity)
      this.map.setPaintProperty('layer-radar-full', 'raster-opacity', this.radarOpacity)

      // draw wind arrows on the map
      this.updateWindSource()
      if (this.isLayerWindArrowsDisplayed === false) {
        this.event.stations.forEach((_, index) => {
          this.hideLayer(`wind-arrow-layer-${index}`)
          this.hideLayer(`wind-head-layer-${index}`)
          this.hideLayer(`gust-arrow-layer-${index}`)
          this.hideLayer(`gust-head-layer-${index}`)
        })
      }
    })

    const onLayerStationsClick = (e) => {
      const stationIndex = +e.features[0].properties.reference
      // sometimes, item could be null (station is off)
      const stationDetail = this.stationsData.find(item => item ? item.stationId === stationIndex : false)
      if (stationDetail) {
        const coordinates = e.features[0].geometry.coordinates.slice()
        this.showStationPopup(coordinates, stationDetail, e.features[0].properties.position)
      }
    }

    /**
     * Display a popup on a mousemove on a wind layer
     */
    const onLayerWindMouseMove = e => {
      this.map.getCanvas().style.cursor = 'pointer'
      const stationIndex = +e.features[0].properties.index
      const stationDetail = this.stationsData[stationIndex]
      const station = this.event.stations[stationIndex]
      // sometimes, item could be null (station is off)
      const popup = this.windPopups[station.properties.reference] || new mapboxgl.Popup({
        className: 'wind-popup',
        closeButton: false,
        closeOnClick: false
      })
      if (stationDetail) {
        popup.setLngLat(e.lngLat)
          .setHTML(
            `Ground station: ${station.properties.reference}<br/>
            Wind: ${stationDetail.windSpeed}kph<br/>
            Wind gusts: ${stationDetail.gusts}kph
          `)
          .addTo(this.map)
        this.windPopups[station.properties.reference] = popup
      }
    }

    /**
     * Remove the popup when the mouse leave a wind layer
     * @param reference Reference of the popup
     */
    const onLayerWindMouseLeave = reference => () => {
      this.map.getCanvas().style.cursor = ''
      if (this.windPopups[reference]) {
        this.windPopups[reference].remove()
        delete this.windPopups[reference]
      }
    }

    // On click on stations, show popup with station data
    this.map.on('click', 'layer-stations-circle', onLayerStationsClick)
    this.map.on('click', 'layer-stations-circle-over-limit', onLayerStationsClick)

    // Enable the events mousemove / ... for all the wind layers (speed / gusts)
    this.event.stations.forEach((station, index) => {
      this.map.on('mousemove', `wind-arrow-layer-${index}`, onLayerWindMouseMove)
      this.map.on('mouseleave', `wind-arrow-layer-${index}`, onLayerWindMouseLeave(station.properties.reference))
      this.map.on('mousemove', `wind-head-layer-${index}`, onLayerWindMouseMove)
      this.map.on('mouseleave', `wind-head-layer-${index}`, onLayerWindMouseLeave(station.properties.reference))
      this.map.on('mousemove', `gust-arrow-layer-${index}`, onLayerWindMouseMove)
      this.map.on('mouseleave', `gust-arrow-layer-${index}`, onLayerWindMouseLeave(station.properties.reference))
      this.map.on('mousemove', `gust-head-layer-${index}`, onLayerWindMouseMove)
      this.map.on('mouseleave', `gust-head-layer-${index}`, onLayerWindMouseLeave(station.properties.reference))
    })

    this.map.on('zoomend', () => {
      preferences.updateZooms(mapZoomPrefKey, this.map.getZoom())
    })

    // we stop & start animation if user move the map
    // in fact, the movestart is always triggered (event if you drag / rotate / pitch / ...)
    // so we only need to listen to this event
    const eventsToEmit = ['movestart', 'moveend']
    eventsToEmit.forEach(eventType => {
      this.map.on(eventType, () => this.$emit(eventType))
    })
  },

  beforeDestroy () {
    this.savePrefs = false
    this.map.remove()
  },

  methods: {

    /**
     * Initialize stations popup from user preference (store in localstorage)
     */
    createOrReplaceStationPopup () {
      if (!this.map) {
        return
      }

      const availableStations = []
      // If station data loaded, map over stations
      if (this.stationsData && this.stationsData.length) {
        for (let index = 0; index < this.event.stations.length; index++) {
          const station: WeatherStationData = this.stationsData[index]
          if (station) {
            availableStations.push(station.stationId)
            const prefKey = `map_${this.id}_station_${this.event.id}_${station.stationId}`
            if (preferences.stations[prefKey] === true) {
              const coordinates = this.event.stations[index].geometry.coordinates.slice()
              this.showStationPopup(coordinates, station, this.event.stations[index].properties.position)
            }
          }
        }

        // Apply filters to show only available station
        // this.map.setFilter(layerCircleName, ['in', 'reference', ...availableStations])
        // this.map.setFilter(layerSymbolName, ['in', 'reference', ...availableStations])
        // Remove popup of not available stations
        // Object.keys(this.stationPopups).forEach(popupKey => {
        //   if (availableStations.indexOf(parseInt(popupKey)) === -1) {
        //     this.stationPopups[popupKey].remove()
        //     delete this.stationPopups[popupKey]
        //   }
        // })
      }
    },

    /**
   *
   *
   * @param {array|object} coordinates
   * @param {object} stationDetail Object contains a single station data
   * @memberof RadarMap
   */
    showStationPopup (coordinates, stationDetail: WeatherStationData, position) {
      const prefKey = `map_${this.id}_station_${this.event.id}_${stationDetail.stationId}`
      const linearOffset = Math.round(Math.sqrt(0.5 * Math.pow(20, 2)))

      const indexOfSpaceInPosition = position.indexOf(' ')
      const firstPosition = indexOfSpaceInPosition > -1
        ? position.substring(0, indexOfSpaceInPosition)
        : position
      let reversePosition
      if (firstPosition === 'left') reversePosition = 'right'
      if (firstPosition === 'right') reversePosition = 'left'
      if (firstPosition === 'top') reversePosition = 'bottom'
      if (firstPosition === 'bottom') reversePosition = 'top'

      if (this.stationPopups[stationDetail.stationId]) {
        this.stationPopups[stationDetail.stationId].setHTML(getStationPopupContent(stationDetail))
      } else {
        const popup = new mapboxgl.Popup({
          offset: {
            left: [linearOffset, 0],
            right: [-linearOffset, 0],
            top: [0, linearOffset],
            bottom: [0, -linearOffset]
          },
          closeOnClick: false,
          // Mapbox anchor logic is reverse from position
          anchor: reversePosition,
          className: 'station-popup' + (stationDetail.isRainIntensityOverLimit === true ? ' overlimit' : '')
        })
          .setLngLat(coordinates)
          .setHTML(getStationPopupContent(stationDetail))
          .addTo(this.map)

        preferences.updateStations(prefKey, true)

        popup.on('close', () => {
          if (this.savePrefs === true) { preferences.updateStations(prefKey, false) }
          delete this.stationPopups[stationDetail.stationId]
        })

        this.stationPopups[stationDetail.stationId] = popup
      }
    },

    /**
     * Add Mapbox Source and Layer to the map,
     * to display the tiles/full_image of a timestamp radar image
     *
     * @param {string} timestamp
     *   datetime string to know which time we have to add to the map
     */
    addSourceAndLayer (timestamp): void {
      if (!timestamp) return
      if (!this.bbox) return
      const imageURL = TILES_RADAR_URL + timestamp + this.imageResolution
      this.activeSourceName = SOURCE_RADAR_FULL
      if (!this.map.getSource(SOURCE_RADAR_FULL)) {
        const coords = this.bbox.coordinates[0]

        this.map.addSource(SOURCE_RADAR_FULL, {
          type: 'image',
          url: imageURL,
          coordinates: [
            [coords[1][0], coords[1][1]],
            [coords[2][0], coords[2][1]],
            [coords[3][0], coords[3][1]],
            [coords[0][0], coords[0][1]]
          ]
        })
      } else {
        this.map.getSource(SOURCE_RADAR_FULL).updateImage({
          url: imageURL
        })
      }
      // }
      /**
       * we create the layer only if it doesn't exist yet
       */
      const layerName = 'layer-radar-full'
      const layer = this.map.getLayer(layerName)
      if (!layer) {
        this.map.addLayer({
          id: layerName,
          type: 'raster',
          source: SOURCE_RADAR_FULL,
          paint: {
            'raster-fade-duration': 0,
            'raster-resampling': 'nearest',
            'raster-opacity': 1,
            'raster-opacity-transition': {
              duration: 0,
              delay: 0
            }
          }
        }, 'layer-event-target-line')
      }
      this.map.triggerRepaint()
    },

    /**
     * Show the layer with the current layerId
     * @param {string} layerId
     */
    showLayer (layerId): void {
      const layer = this.map.getLayer(layerId)
      layer && this.map.setLayoutProperty(layerId, 'visibility', 'visible')
    },

    /**
     * Hide the layer with the current layerId
     * @param {string} layerId
     */
    hideLayer (layerId): void {
      const layer = this.map.getLayer(layerId)
      layer && this.map.setLayoutProperty(layerId, 'visibility', 'none')
    },

    toggleLayer (layerId): void {
      if (layerId && this.map.getLayer(layerId)) {
        const visibility = this.map.getLayoutProperty(layerId, 'visibility')
        if (visibility === 'visible' || visibility === undefined) {
          this.map.setLayoutProperty(layerId, 'visibility', 'none')
        } else {
          this.map.setLayoutProperty(layerId, 'visibility', 'visible')
        }
      }
    },

    drawRainpath (speed, direction) {
      if (!this.map || !this.map.isStyleLoaded()) {
        return false
      }
      this.map.getSource('source-rainpath')
        .setData(getRainpathSource(this.event.location.coordinates, speed, direction).data)
    },

    updateSourceStationOverLimit () {
      if (!this.map) {
        return
      }
      if (!this.stationsOverLimit) {
        this.hideLayer('layer-stations-circle-over-limit')
        return
      }

      this.map.getSource('source-stations-over-limit')
        .setData({
          type: 'FeatureCollection',
          features: this.stationsOverLimit
        })
      // we show the circle over limit only if user want to
      if (this.isLayerWeatherStationsDisplayed) {
        this.showLayer('layer-stations-circle-over-limit')
      }
    },
    getWindArrow (center, direction, speed, index, type) {
      // the middle of wind arrow is the center
      // so we divide the distance by 12 (equal to 5mn of distance, windSpeed is in kph)
      // then by 2
      const distance = speed / 12 / 2
      const color = this.colors[index][type]

      const arrowProps = { color, name: `${type}-arrow-${index}`, index }
      const headProps = { color, name: `${type}-head-${index}`, index }

      const arrow = getWindArrow(center, direction, distance, arrowProps)
      const head = drawArrowHead(center, direction - 180, distance, headProps)
      return [head, arrow]
    },

    updateWindSource () {
      const featuresWindSpeed = []
      const featuresWindGust = []
      this.event.stations.forEach((station: WeatherEventStation, index: number) => {
        const stationData: WeatherStationData = this.stationsData && this.stationsData[index]
        if (stationData) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { windDirectionDegree, windSpeed, gusts, stationId } = stationData
          const { geometry: { coordinates } } = station
          const windDirection = windDirectionDegree - 180

          const [meanHead, mean] = this.getWindArrow(coordinates, windDirection, windSpeed, index, 'wind')
          featuresWindSpeed.push(mean, meanHead)

          const [gustHead, gust] = this.getWindArrow(coordinates, windDirection, gusts, index, 'gust')
          featuresWindGust.push(gust, gustHead)
        }
      })
      this.map.getSource('source-wind-speed')
        .setData({
          type: 'FeatureCollection',
          features: featuresWindSpeed
        })

      this.map.getSource('source-wind-gust')
        .setData({
          type: 'FeatureCollection',
          features: featuresWindGust
        })
    }
  },
  watch: {
    currentTime (newValue: WeatherTime) {
      this.addSourceAndLayer(newValue.utc)
    },
    rain (newValue) {
      if (newValue) {
        this.drawRainpath(newValue.speed, newValue.direction)
      }
    },
    isLayerOsmDisplayed () {
      this.toggleLayer('layer-raster-osm')
    },
    isLayerRainpathDisplayed () {
      this.toggleLayer('layer-rainpath')
      this.toggleLayer('layer-rainpath-head')
    },
    isLayerWeatherStationsDisplayed (newValue) {
      [
        'layer-stations-circle',
        'layer-stations-symbol',
        'layer-stations-circle-over-limit'
      ].forEach(layerId => this.toggleLayer(layerId))
      if (newValue === true) {
        this.createOrReplaceStationPopup()
        this.savePrefs = true
      } else {
        this.savePrefs = false
        this.stationPopups.forEach(p => p.remove())
      }
    },
    stationsData () {
      this.createOrReplaceStationPopup()
      this.updateWindSource()
    },
    stationsOverLimit () {
      this.updateSourceStationOverLimit()
    },
    centerMap () {
      setTimeout(() => {
        this.map.resize()
      }, 300)
    },
    osmOpacity () {
      this.map.setPaintProperty('layer-raster-osm', 'raster-opacity', this.osmOpacity)
    },
    radarOpacity () {
      this.map.setPaintProperty('layer-radar-full', 'raster-opacity', this.radarOpacity)
    },
    isLayerWindArrowsDisplayed () {
      this.event.stations.forEach((_, index) => {
        this.toggleLayer(`wind-arrow-layer-${index}`)
        this.toggleLayer(`wind-head-layer-${index}`)
        this.toggleLayer(`gust-arrow-layer-${index}`)
        this.toggleLayer(`gust-head-layer-${index}`)
      })
    }
  }
})
